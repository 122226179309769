(function ($) {
  Drupal.behaviors.secondaryNavV2 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;
      $('.site-container', context).addClass('relativepos');
    }
  };
})(jQuery);
